/* global config, moment, zingchart */

'use strict';
angular.module('managerApp.partner').controller('PartnerController', (
        $rootScope, $scope, $timeout, $route, $cookies, $location, $mdDialog, toast, clients,
        $_PrepaidAccounts, procedureService, $_DevicesManagers, _type, _templatesDir, dashboard,
        procedureTools, $_myCookies, $_procedures) => {

//------------------------------------------------------------------------------

    $scope.config = config;
    const TEMPLATES_DIR = config.app.templatesDir;
    const DEFAULT_ERROR_MESSAGE = config.app.defaultErrorMessage;
    const type = _type;
    const paymentRequestsPaginationCookie = 'pagination.paymentRequests.itemsPerPage';
    const prepaidAccountEventsPaginationCookie = 'pagination.prepaidAccount.events.itemsPerPage';

//------------------------------------------------------------------------------

    $scope.client = $rootScope.currentUser;
    $scope.currentUser = $rootScope.currentUser;

    if ('code' in $scope.currentUser && 'PartnerId' in $scope.currentUser) {
        $location.path('/logout');
    }

    $scope.paymentRequests = {};

    $scope.hasPrepaidAccount = $scope.currentUser.PrepaidAccount !== null;
    $scope.isTerminalPartner = $scope.currentUser.TerminalPartner !== null;
    $scope.isTabletPartner = $scope.currentUser.TabletPartner !== null;
    $scope.isWebAppPartner = $scope.currentUser.WebAppPartner !== null;
    $scope.defaultDeviceType = $scope.isTerminalPartner ? 'terminal' : ($scope.isTabletPartner ? 'tablet' : ($scope.isWebAppPartner ? 'webapp' : null));

    $scope.partnerCommission = getPartnerCommission();

    $scope.prepaidAccountId = $_myCookies.get('prepaidAccountId');

//------------------------------------------------------------------------------

    $scope._paymentRequests = {
        pagination: {
            currentPage: 1,
            maxSize: 5,
            itemsPerPage: $cookies.get(paymentRequestsPaginationCookie) || 10,
            totalItems: 0,
            isAll: false,
            isWebApp: false,
            status: 'all',
            pendingOnly: false,
            completedOnly: false,
            deviceType: null, //$scope.defaultDeviceType,
            setStatus: function () {
                this.pendingOnly = false;
                this.completedOnly = false;
                switch (this.status) {
                    case 'all':
                        break;
                    case 'completed':
                        this.completedOnly = true;
                        break;
                    case 'pending':
                        this.pendingOnly = true;
                        break;
                }
                this.run();
            },
            setDeviceType: function (deviceType) {
                this.deviceType = deviceType;
                this.isAll = deviceType === 'all';
                this.isWebApp = deviceType === 'webapp';
                this.run();
            },
            run: function () {
                $cookies.put(paymentRequestsPaginationCookie, this.itemsPerPage);
                getPaymentRequests(this.deviceType, this.completedOnly, this.pendingOnly);
            }
        }
    };

    $scope._events = {
        pagination: {
            maxSize: 5,
            totalItems: 0,
            currentPage: 1,
            itemsPerPage: $cookies.get(prepaidAccountEventsPaginationCookie) || 10,
            type: 'credit',
            pageChanged: function () {
                $cookies.put(prepaidAccountEventsPaginationCookie, this.itemsPerPage);
                $scope._events.get($scope.prepaidAccountId, this.type);
            }
        },
        onTabSelect: function (type) {
            this.pagination.type = type;
            this.pagination.pageChanged();
        },
        getMethodLabel: function (method) {
            switch (method) {
                case 'paiement':
                    return 'Paiement pour une démarche';
                case 'remboursement':
                    return 'Remboursement';
                case 'depot':
                    return 'Dépôt';
                default:
                    return null;
            }
        },
        get: function (prepaidAccountId, type) {
            if (!prepaidAccountId) {
                return;
            }
            let params = {};
            if (type && ['debit', 'credit'].includes(type)) {
                params.type = type;
            }
            $_PrepaidAccounts.getEvents(
                    prepaidAccountId, params,
                    this.pagination.currentPage,
                    this.pagination.itemsPerPage).then((_data) => {
                let events = [];
                _data.rows.forEach((row) => {
                    let item = Object.assign({}, row);
                    if (row.method) {
                        item.methodLabel = this.getMethodLabel(row.method);
                    }
                    if (row.details) {
                        let details = this.parseEventDetails(row.details);
                        if (details) {
                            item = Object.assign(details, item);
                        }
                    }
                    events.push(item);
                });
                $scope.prepaidAccountEvents = events;
                this.pagination.totalItems = _data.count;
                $scope.$apply();
            });
        },
        parseEventDetails: function (details) {
            let result = {};
            if (typeof details === 'string' && details !== '') {
                try {
                    let data = JSON.parse(details);
                    if (data) {
                        if (data.procedure) {
                            result.procedure = {
                                number: data.procedure.number,
                                type: data.procedure.type,
                                name: procedureTools.getProcedureName(data.procedure.type)
                            };
                        }
                        if (data.vehicle) {
                            result.vehicle = {
                                make: data.vehicle.make,
                                registrationNum: data.vehicle.registrationNum
                            };
                        }
                    }
                    return result;
                } catch (e) {
                    return null;
                }
            }
            return null;
        }
    };

//------------------------------------------------------------------------------

    function getPartnerCommission() {
        if (!('TerminalPartner' in $scope.currentUser) &&
                !('TabletPartner' in $scope.currentUser) &&
                !('WebAppPartner' in $scope.currentUser)) {
            return;
        }
        let c1 = 25, c2 = 25, c3 = 25;
        if ($scope.isTerminalPartner) {
            c1 = $scope.currentUser.TerminalPartner.commission;
            if ($scope.currentUser.TerminalPartner.acceptRCGPayments === false) {
                return 0;
            }
        }
        if ($scope.isTabletPartner) {
            c2 = $scope.currentUser.TabletPartner.commission;
            if ($scope.currentUser.TabletPartner.acceptRCGPayments === false) {
                return 0;
            }
        }
        if ($scope.isWebAppPartner) {
            c3 = $scope.currentUser.WebAppPartner.commission;
            if ($scope.currentUser.WebAppPartner.acceptRCGPayments === false) {
                return 0;
            }
        }
        let c = Math.max(c1, c2, c3, 25);
        return c / 100;
    }

    $scope.getWebApps = (cb = null) => {
        if (!$scope.client.webapps) {
            clients.getMyWebApps().then((data) => {
                $cookies.put(config.app.cookies.webappToken, data.webAppToken);
                $scope.client.webapps = data;
                $scope.$apply();
                if (typeof cb === 'function') {
                    cb(data);
                }
            });
        } else {
            if (typeof cb === 'function') {
                cb($scope.client.webapps);
            }
        }//
    };

    $scope.getTerminals = (cb = null) => {
        if (!$scope.client.terminals) {
            clients.getMyTerminals().then((data) => {
                $scope.client.terminals = data;
                $scope.$apply();
                if (typeof cb === 'function') {
                    cb(data);
                }
            });
        } else {
            if (typeof cb === 'function') {
                cb($scope.client.terminals);
            }
        }//
    };
    $scope.getTablets = (cb = null) => {
        if (!$scope.client.tablets) {
            clients.getMyTablets().then((data) => {
                $scope.client.tablets = data;
                $scope.$apply();
                if (typeof cb === 'function') {
                    cb(data);
                }
            });
        } else {
            if (typeof cb === 'function') {
                cb($scope.client.tablets);
            }
        }//
    };
    $scope.createPrepaidAccount = (target, type) => {
        let client = $scope.client;
        let data = {
            balance: parseInt(target.PrepaidAccount.actualBalance),
            PrepaidAccountId: $scope.prepaidAccountId
        };
        data[type + 'Id'] = target._id;
        clients.createPrepaidAccount(client._id, data).then(() => {
            toast.show('Le solde a bien été mis à jour.', 'success');
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
        });
    };
    $scope.updatePrepaidAccount = (target, field) => {
        let fields = [
            'terminals', 'tablets',
            'allocatedTerminalsBalance', 'allocatedTabletsBalance'
        ];
        if (['terminals', 'tablets'].indexOf(field) > -1) {
            target[field] = !target[field];
        }
        if (fields.indexOf(field) > -1) {
            let data = {};
            data[field] = target[field];
            $_PrepaidAccounts.update(target._id, data).then(() => {
                toast.show('Le compte prépayé a bien été mis à jour.', 'success');
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }
    };

    function getPaymentRequests(deviceType = 'terminal', completedOnly = false, pendingOnly = false) {
        let params = {
            deviceType: deviceType,
            prepaidAccountId: $scope.client.PrepaidAccount._id
        };
        if (completedOnly === true) {
            params.completed = 1;
        } else if (pendingOnly === true) {
            params.pending = 1;
        }
        $_PrepaidAccounts.getPaymentRequests(params, {
            page: $scope._paymentRequests.pagination.currentPage,
            pageSize: $scope._paymentRequests.pagination.itemsPerPage
        }).then((data) => {
            $scope.paymentRequests.items = [];
            $scope.paymentRequests.items = data.items || [];
            $scope._paymentRequests.pagination.totalItems = data.count;
            $scope.$apply();
        });
    }

    $scope.getPaymentRequests = (deviceType = 'terminal') => {
        $scope._paymentRequests.pagination.setDeviceType(deviceType);
        //$scope._paymentRequests.pagination.completedOnly = completedOnly;
        //getPaymentRequests(deviceType), completedOnly);
    };

    $scope.showValidatePaymentRequestPopup = (paymentRequest) => {
        $mdDialog.show({
            templateUrl: _templatesDir + 'validate.payment.request.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {
                paymentRequest: paymentRequest,
                __paymentRequests: $scope._paymentRequests,
                prepaidAccount: $scope.client.PrepaidAccount._id
            },
            controller: ($scope, toast, paymentRequest, __paymentRequests, prepaidAccount) => {
                $scope.prepaidAccount = prepaidAccount;
                $scope.paymentRequest = paymentRequest;
                $scope.device = paymentRequest.Source;
                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        $_PrepaidAccounts.validateOrRejectPaymentRequest(prepaidAccount, paymentRequest._id, 'validate', {
                            deviceId: $scope.device._id,
                            deviceType: $scope.device.type,
                            method: $scope.paymentRequest.method
                        }).then((res) => {
                            $mdDialog.hide();
                            __paymentRequests.pagination.run();
                            toast.show('Le paiement a bien été validé.', 'success');
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    } else {
                        $mdDialog.hide();
                    }//
                };
            }
        }).then((answer) => {
            console.log('answer : ' + answer);
        }, () => {
            console.log('You cancelled the dialog.');
        });
    };

    $scope.reject = (paymentRequest) => {
        let prepaidAccountId = $scope.client.PrepaidAccount._id;
        let device = paymentRequest.Source;
        if (confirm('Voulez vous vraiment annuler cette demande de paiement ?')) {
            $_PrepaidAccounts.validateOrRejectPaymentRequest(prepaidAccountId, paymentRequest._id, 'reject', {
                deviceId: device._id,
                deviceType: device.type
            }).then((res) => {
                $scope._paymentRequests.pagination.run();
                toast.show('La demande de paiement a bien été refusée.', 'success');
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }
    };

    $scope.addRefund = (paymentRequest) => {
        if (paymentRequest.refundable === true) {
            $mdDialog.show({
                templateUrl: TEMPLATES_DIR + 'refund.html',
                parent: angular.element(document.body),
                fullscreen: true,
                locals: {
                    procedureId: paymentRequest.Procedure._id,
                    totalPaid: paymentRequest.Procedure.totalToPay,
                    totalTaxes: paymentRequest.Procedure.totalTaxes || 0
                },
                controller: ($scope, $mdDialog, toast, procedureTools, procedureId, totalPaid, totalTaxes) => {
                    $scope.isPartner = true;
                    $scope.procedureId = procedureId;
                    $scope.refund = {};
                    $scope.refund.totalPaid = totalPaid;
                    $scope.refund.totalTaxes = totalTaxes;
                    $scope.refund.totalToRefund = totalTaxes;
                    $scope.defaultMotifs = [
                        "Le client n'a pas tous les documents nécessaires pour la démarche",
                        "Le contrôle technique n'est pas / plus valide",
                        "Le client s'est trompé de la démarche",
                        "Carte grise étrangère",
                        "Permis étranger",
                        "La démarche est bloquée à cause d'une carte grise non faite",
                        "Le véhicule est gagé",
                        "Démarche ou paiement en double",
                        "Paiement des taxes en trop",
                        "Démarche déjà faite ailleurs",
                        "Autre motif"
                    ];
                    $scope.onRefundTypeChange = () => {
                        if ($scope.refund.type === 'total') {
                            $scope.refund.totalToRefund = $scope.refund.totalPaid;
                        } else if ($scope.refund.type === 'taxes-uniquement') {
                            $scope.refund.totalToRefund = $scope.refund.totalTaxes;
                        } else if ($scope.refund.type === 'partiel') {
                            $scope.refund.totalToRefund = $scope.refund.totalTaxes;
                        }
                    };
                    $scope.closeDialog = (confirm = false) => {
                        $mdDialog.hide();
                        if (confirm) {
                            procedureService.createRefund($scope.procedureId, $scope.refund).then(() => {
                                toast.show('La demande de remboursement a bien été enregistrée.', 'success');
                                $route.reload();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                            });
                        }//
                    };
                }
            }).then(() => {
            }, () => {
            });
        }
    };
    $scope.creditDevicePrepaidBalance = (device, deviceType) => {
        let prepaidAccountId = $scope.client.PrepaidAccount._id;
        let data = {
            deviceType: deviceType,
            amount: parseInt(device.prepaidBalance)
        };
        $_PrepaidAccounts.creditDevicePrepaidBalance(prepaidAccountId, device._id, data).then(() => {
            toast.show('Le solde prépayé a bien été mis à jour', 'success');
            //$route.reload();
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
        });
    };
    $scope.getDevicesManagers = (force = false) => {
        if (!$scope.client.devicesManagers || force === true) {
            clients.getMyDevicesManagers().then((data) => {
                if (data && Array.isArray(data)) {
                    for (let i = 0; i < data.length; i++) {
                        let dm = data[i];
                        dm.title = dm.title === 'm' ? 'M.' : 'Mme';
                    }
                }
                $scope.client.devicesManagers = data;
                $scope.$apply();
            });
    }
    };
    $scope.showAddDevicesManagerPopup = () => {
        $mdDialog.show({
            templateUrl: _templatesDir + 'add.devices.manager.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {
                // client id
                partnerId: $scope.client._id
            },
            controller: ($scope, toast) => {
                $scope.devicesManager = {};
                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        clients.addDevicesManager($scope.devicesManager).then((res) => {
                            toast.show('Le gestionnaire des appareils a bien été ajouté.', 'success');
                            $mdDialog.hide();
                            $route.reload();
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    } else {
                        $mdDialog.hide();
                }
                };
            }
        }).then((answer) => {
            console.log('answer : ' + answer);
        }, () => {
            console.log('You cancelled the dialog.');
        });
    };
    $scope.showDevicesPopup = (devicesManager) => {
        $mdDialog.show({
            templateUrl: _templatesDir + 'add.remove.devices.html',
            parent: angular.element(document.body),
            fullscreen: true,
            locals: {
                devicesManager: devicesManager,
                getTerminals: $scope.getTerminals,
                getTablets: $scope.getTablets,
                isTabletPartner: $scope.isTabletPartner,
                isTerminalPartner: $scope.isTerminalPartner
            },
            controller: ($scope, toast, $_DevicesManagers, devicesManager, getTerminals, getTablets, isTabletPartner, isTerminalPartner) => {

                $scope.isTerminalPartner = isTerminalPartner;
                $scope.isTabletPartner = isTabletPartner;

                $scope.devicesManagerId = devicesManager._id;
                $scope.devicesManagerName = devicesManager.title + ' ' + devicesManager.lastname;
                $scope.getTerminals = $scope.isTerminalPartner ? getTerminals : () => {
                };
                $scope.getTablets = $scope.isTabletPartner ? getTablets : () => {
                };

                $scope.getTerminals((terminals) => {
                    $scope.terminals = terminals;
                });
                $scope.getTablets((tablets) => {
                    $scope.tablets = tablets;
                });

                $scope.addDevice = (device, deviceType) => {
                    $_DevicesManagers.addRemoveDevice($scope.devicesManagerId, device._id, deviceType, 'add').then(() => {
                        device.DevicesManagerId = $scope.devicesManagerId;
                        $scope.$apply();
                    });
                };
                $scope.removeDevice = (device, deviceType) => {
                    $_DevicesManagers.addRemoveDevice($scope.devicesManagerId, device._id, deviceType, 'remove').then(() => {
                        device.DevicesManagerId = null;
                        $scope.$apply();
                    });
                };
                $scope.closeDialog = () => {
                    $mdDialog.hide();
                };
            }
        }).then((answer) => {
            console.log('answer : ' + answer);
        }, () => {
            console.log('You cancelled the dialog.');
        });
    };

    $scope.disableAccount = (devicesManager) => {
        if (confirm('Voulez-vous vraiment désactiver ce compte ?')) {
            $_DevicesManagers.disableAccount(devicesManager._id).then((res) => {
                toast.show(res.message, 'success');
                $scope.getDevicesManagers(true);
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }
    };
    $scope.enableAccount = (devicesManager) => {
        if (confirm('Voulez-vous vraiment désactiver ce compte ?')) {
            $_DevicesManagers.enableAccount(devicesManager._id).then((res) => {
                toast.show(res.message, 'success');
                $scope.getDevicesManagers(true);
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }
    }

    /*$scope.addRemoveDevice: () => {
     $_DevicesManagers.addRemoveDevice: (devicesManagerId = null, deviceId = null, deviceType = null, action = null) => {
     }*/

//--------------------------------------------------------------- dashboard ----

    let _partner = $scope.currentUser;

    function getShortMonth(month) {
        month--;
        return [
            'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin',
            'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'
        ][month];
    }

    const COLORS = {
        cim: {bgColor: '#e30517'},
        cad: {bgColor: '#448085'},
        dup: {bgColor: '#0075F6'},
        dc: {bgColor: '#8f53d0'},
        vel: {bgColor: '#00beca'},
        da: {bgColor: '#118400'},
        csa: {bgColor: '#56423e'}
    };
    let maxDate = new Date();
    let minDate = new Date(2020, 1, 1);
//------------------------------------------------------------------------------    

    $scope.getEvolutionsStats = {
        id: '2f2ebc4a-5e79-4131-b8bc-2aaad129c36f',
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: _partner.TerminalPartner !== null ? _partner.TerminalPartner._id : null,
        tabletId: null,
        tabletPartnerId: _partner.TabletPartner !== null ? _partner.TabletPartner._id : null,
        webAppId: null,
        webAppPartnerId: _partner.WebAppPartner !== null ? _partner.WebAppPartner._id : null,
        chart: 'chart_01',
        chart_global: 'chart_global_01',
        chartType: 'bar',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: null,
        terminals: null,
        tablets: null,
        webapps: null,
        origin: null,
        selectChartType: function () {
            $cookies.put('charts.' + this.chart + '.type', this.chartType);
            this.draw();
        },
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectDevice: function (type) {
            if (type === 'terminal') {
                this.tabletId = null;
                this.webAppId = null;
            } else if (type === 'tablet') {
                this.terminalId = null;
                this.webAppId = null;
            } else if (type === 'webapp') {
                this.terminalId = null;
                this.tabletId = null;
            }
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {

            this.chart = 'chart_' + this.id;
            let chartType = $cookies.get('charts.' + this.chart + '.type');
            if (chartType) {
                this.chartType = chartType;
            }

            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (this.terminalPartnerId !== null && this.terminals === null) {
                $scope.getTerminals((terminals) => {
                    this.terminals = terminals;
                });
            }
            if (this.tabletPartnerId !== null && this.tablets === null) {
                $scope.getTablets((tablets) => {
                    this.tablets = tablets;
                });
            }
            if (this.webAppPartnerId !== null && this.webapps === null) {
                $scope.getWebApps((webapps) => {
                    this.webapps = webapps;
                });
            }
            if (run === true && this.data === null) {
                this.run();
            }//
        },
        run: function () {

            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            };
            if (this.terminalId !== null) {
                if (this.terminalId !== 'all') {
                    params.terminalId = this.terminalId;
                }
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.tabletId !== null) {
                if (this.tabletId !== 'all') {
                    params.tabletId = this.tabletId;
                }
                params.tabletPartnerId = this.tabletPartnerId;
            }
            if (this.webAppId !== null) {
                if (this.webAppId !== 'all') {
                    params.webAppId = this.webAppId;
                }
                params.webAppPartnerId = this.webAppPartnerId;
            }
            if (this.terminalId === null && this.tabletId === null && this.webAppId === null) {
                if (params.terminalPartnerId !== null) {
                    params.terminalPartnerId = this.terminalPartnerId;
                }
                if (params.tabletPartnerId !== null) {
                    params.tabletPartnerId = this.tabletPartnerId;
                }
                if (params.webAppPartnerId !== null) {
                    params.webAppPartnerId = this.webAppPartnerId;
                }
            }

            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }

            clients.dashboard_getEvolutionStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : DEFAULT_ERROR_MESSAGE, 'error');
            });
        },
        draw: function (global = false) {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }

            let chartConfig = {
                type: this.chartType,
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 10,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values,
                        backgroundColor: '#09f',
                        lineColor: '#09f'
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    $scope.getCashPaymentsExport = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        selectedMonth: null,
        selectMonth() {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        init() {
            this.selectedMonth = moment().startOf('month');
            this.dateFrom = moment().startOf('month');
            this.dateTo = moment().endOf('month');
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            };
            clients.dashboard_getCashPaymentsExport(params).then((result) => {
                if (result && result.file) {
                    downloadFile(result.file);
                }
            }).catch((err) => {
                toast.show(err ? err : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }
    };

    $scope.getTurnoverExport = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        selectedMonth: null,
        isSharedWebApp: false,
        selectMonth() {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        init() {
            this.selectedMonth = moment().startOf('month');
            this.dateFrom = moment().startOf('month');
            this.dateTo = moment().endOf('month');
            if ($scope.isWebAppPartner) {
                $scope.getWebApps((webapps) => {
                    if (webapps) {
                        this.isSharedWebApp = (webapps[0] || {}).shared;
                    }
                });
            }
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            };
            if ($scope.isTabletPartner) {
                params.tabletPartnerId = $scope.currentUser.TabletPartner._id;
            }
            if ($scope.isTerminalPartner) {
                params.terminalPartnerId = $scope.currentUser.TerminalPartner._id;
            }
            if ($scope.isWebAppPartner) {
                params.webAppPartnerId = $scope.currentUser.WebAppPartner._id;
            }
            if (this.isSharedWebApp === true) {
                params.shared = 1;
            }
            clients.dashboard_getTurnoverExport(params).then((result) => {
                if (result && result.file) {
                    downloadFile(result.file);
                }
            }).catch((err) => {
                toast.show(err ? err : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }
    };

    function downloadFile(fileURL) {
        fetch(fileURL, {
            headers: {
                'x-from': 'bo',
                'x-partner': 1,
                'Authorization': 'Bearer ' + $cookies.get(config.auth.token)
            }
        }).then(response => response.blob()).then(blob => {
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = url;
            link.download = new URL(fileURL).pathname.split('/').pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    $scope.getTurnoverAndTotals = {
        minDate: minDate,
        maxDate: maxDate,
        dateFrom: null,
        dateTo: null,
        data: null,
        totals: null,
        values: {
            cim: {total: 0, count: 0},
            ciww: {total: 0, count: 0},
            civi: {total: 0, count: 0},
            cad: {total: 0, count: 0},
            dup: {total: 0, count: 0},
            dc: {total: 0, count: 0},
            vel: {total: 0, count: 0},
            da: {total: 0, count: 0}
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            if (run === true && this.data === null) {
                this.run();
            }//
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        setTotals: function () {
            this.totals = {};
            let t = 0, g = 0, c = 0;
            for (let i in this.values) {
                t += this.values[i].total;
                c += this.values[i].count;
                g += this.values[i].gain || 0;
            }
            t = (Math.round(t * 100) / 100);
            g = (Math.round(g * 100) / 100);
            this.totals.total = t;
            this.totals.gain = g;
            this.totals.count = c;
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            };
            clients.dashboard_getTurnover(params).then((counts) => {
                this.data = counts;
                this.totals = {total: 0, gain: 0, count: 0};
                let t = 0, g = 0, c = 0;
                ['cim', 'cad', 'dup', 'dc', 'da', 'ciww', 'civi'].forEach((i) => {
                    this.values[i].total = this.data[i + '_total'];
                    this.values[i].count = this.data[i + '_count'];
                    if (this.values[i].total === null) {
                        this.values[i].total = 0;
                        this.values[i].gain = 0;
                    } else {
                        this.values[i].gain = this.values[i].total * $scope.partnerCommission;
                    }
                    t += this.values[i].total;
                    c += this.values[i].count;
                    g += this.values[i].gain || 0;
                    t = (Math.round(t * 100) / 100);
                    g = (Math.round(g * 100) / 100);
                    this.totals.total = t;
                    this.totals.gain = g;
                    this.totals.count = c;
                });

            });
        }
    };

    $scope.getTurnoverPerPeriod = {
        id: '035be577-f349-49b1-963e-73102d1568ca',
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: _partner.TerminalPartner !== null ? _partner.TerminalPartner._id : null,
        tabletId: null,
        tabletPartnerId: _partner.TabletPartner !== null ? _partner.TabletPartner._id : null,
        webAppId: null,
        webAppPartnerId: _partner.WebAppPartner !== null ? _partner.WebAppPartner._id : null,
        chart: 'chart_02',
        chart_global: 'chart_global_02',
        chartType: 'area',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: null,
        terminals: null,
        tablets: null,
        webapps: null,
        origin: null,
        selectChartType: function () {
            $cookies.put('charts.' + this.chart + '.type', this.chartType);
            this.draw();
        },
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectDevice: function (type) {
            if (type === 'terminal') {
                this.tabletId = null;
                this.webAppId = null;
            } else if (type === 'tablet') {
                this.terminalId = null;
                this.webAppId = null;
            } else if (type === 'webapp') {
                this.terminalId = null;
                this.tabletId = null;
            }
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {

            this.chart = 'chart_' + this.id;
            let chartType = $cookies.get('charts.' + this.chart + '.type');
            if (chartType) {
                this.chartType = chartType;
            }

            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (this.terminalPartnerId !== null && this.terminals === null) {
                $scope.getTerminals((terminals) => {
                    this.terminals = terminals;
                });
            }
            if (this.tabletPartnerId !== null && this.tablets === null) {
                $scope.getTablets((tablets) => {
                    this.tablets = tablets;
                });
            }
            if (this.webAppPartnerId !== null && this.webapps === null) {
                $scope.getWebApps((webapps) => {
                    this.webapps = webapps;
                });
            }
            if (run === true && this.data === null) {
                this.run();
            }//
        },
        run: function () {

            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            };
            if (this.terminalId !== null) {
                if (this.terminalId !== 'all') {
                    params.terminalId = this.terminalId;
                }
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.tabletId !== null) {
                if (this.tabletId !== 'all') {
                    params.tabletId = this.tabletId;
                }
                params.tabletPartnerId = this.tabletPartnerId;
            }
            if (this.webAppId !== null) {
                if (this.webAppId !== 'all') {
                    params.webAppId = this.webAppId;
                }
                params.webAppPartnerId = this.webAppPartnerId;
            }
            if (this.terminalId === null && this.tabletId === null && this.webAppId === null) {
                if (params.terminalPartnerId !== null) {
                    params.terminalPartnerId = this.terminalPartnerId;
                }
                if (params.tabletPartnerId !== null) {
                    params.tabletPartnerId = this.tabletPartnerId;
                }
                if (params.webAppPartnerId !== null) {
                    params.webAppPartnerId = this.webAppPartnerId;
                }
            }

            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }

            clients.dashboard_getTurnoverPerPeriod(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : DEFAULT_ERROR_MESSAGE, 'error');
            });
        },
        draw: function (global = false) {
            let labels = [], totals = [], gains = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                let total = row.total;
                if (typeof row.total === 'undefined') {
                    total = parseFloat(row.cad_total || 0) + parseFloat(row.cim_total || 0) +
                            parseFloat(row.dc_total || 0) + parseFloat(row.dup_total || 0);
                }
                let gain = (total * $scope.partnerCommission);
                gain = (Math.round(gain * 100) / 100);
                totals.push(total);
                gains.push(gain);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }

            let chartConfig = {
                type: this.chartType,
                backgroundColor: '#fff',
                plotarea: {
                    margin: '30px 30px 50px 50px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: '#666',
                    lineWidth: '2px',
                    step: 10,
                    tick: {
                        visible: true
                    }
                },
                series: [
                    {
                        values: totals,
                        backgroundColor: '#00c',
                        lineColor: '#00c'
                    },
                    {
                        values: gains,
                        backgroundColor: '#090',
                        lineColor: '#090'
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    function getPrepaidAccont(clientId) {
        let prepaidAccount = $_myCookies.get('client.' + clientId + '.prepaidAccount');
        if (prepaidAccount) {
            $scope.prepaidAccount = prepaidAccount;
            $scope.prepaidAccount.Client = $scope.client;
            $_myCookies.set('prepaidAccountId', prepaidAccount._id);
            $scope._events.pagination.pageChanged();
        } else {
            clients.getPrepaidAccount(clientId).then((data) => {
                $_myCookies.set('client.' + clientId + '.prepaidAccount', data, 60);
                $_myCookies.set('prepaidAccountId', data._id);
                $scope.prepaidAccount = data;
                $scope.prepaidAccount.Client = $scope.client;
                $scope.$apply();
                $scope._events.pagination.pageChanged();
            });
        }
    }

    function getMyProcedures() {
        $_procedures.get({}, 0, 1000).then((data) => {
            data.items.forEach((p) => {
                p.item = procedureTools.getSubProcedure(p);
            });
            $scope.chat.procedures = data.items;
            $timeout(() => $scope.$apply());
        });
    }



//------------------------------------------------------------------------------

    switch (type) {

        case 'dashboard':

            $scope.getCashPaymentsExport.init();
            $scope.getTurnoverExport.init();
            $scope.getEvolutionsStats.init(true);
            $scope.getTurnoverAndTotals.init(true);
            $scope.getTurnoverPerPeriod.init(true);
            break;

        case 'account':

            console.log($scope.currentUser);
            break;

        case 'prepaid-account':
            getPrepaidAccont($scope.client._id);

            break;

        case 'devices-managers':

            $scope.getDevicesManagers();
            break;

        case 'terminals':

            $scope.getTerminals();
            break;

        case 'tablets':

            $scope.getTablets();
            break;

        case 'payment-requests':

            $scope._paymentRequests.pagination.setDeviceType($scope.defaultDeviceType);
            break;

        case 'chat':

            $scope.chat = {
                procedures: [],
                procedure: null,
                selectedProcedure: null,
                newChatMessage: {message: ''},
                currentChats: [],
                messageInput: 'message-input',
                messagesContainer: '.messages-container',
                init: function () {
                    this.procedures = [];
                    this.procedure = null;
                    this.selectedProcedure = null;
                    this.newChatMessage = {message: ''};
                    this.currentChats = [];

                    this.getCurrentChats();
                    //this.getProcedures();
                },
                getProcedures: function () {
                    $_procedures.get({}, 0, 1000).then((data) => {
                        data.items.forEach((p) => {
                            p.item = procedureTools.getSubProcedure(p);
                        });
                        this.procedures = data.items;
                    });
                },
                selectProcedure: function () {
                    if (typeof this.selectedProcedure === 'string') {
                        this.procedure = JSON.parse(this.selectedProcedure);
                    } else {
                        this.procedure = this.selectedProcedure;
                    }
                    if (this.startNewChat === true) {
                        this.startNewChat = false;
                    } else {
                        this.getChatMessages();
                    }
                },
                formatText: function (message, format) {
                    switch (format) {
                        case 'b':
                            return this.applyFormatting(message, '**');
                        case 'u':
                            return this.applyFormatting(message, '__');

                    }
                },
                applyFormatting: function (message, tag) {
                    let messageInput = document.getElementById(this.messageInput);
                    let selectionStart = messageInput.selectionStart;
                    let selectionEnd = messageInput.selectionEnd;
                    this.newChatMessage.message = message.substring(0, selectionStart) + tag +
                            message.substring(selectionStart, selectionEnd) + tag +
                            message.substring(selectionEnd);
                },
                getCurrentChats: function () {
                    clients.getMyCurrentChats().then((res) => {
                        this.currentChats = res;
                        $timeout(() => $scope.$apply());
                    }).catch((err) => {
                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                    });
                },
                postChatMessage: function () {
                    $_procedures.postChatMessage(this.procedure._id, {
                        message: this.newChatMessage.message
                    }).then(() => {
                        this.newChatMessage.message = '';
                        toast.show('Le message a bien été envoyé.', 'success');
                        this.getChatMessages();
                    }).catch((err) => {
                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                    });
                },
                getChatMessages: function () {
                    $_procedures.getChatMessages(this.procedure._id).then((data) => {
                        this.procedure.chatMessages = data;
                        this.procedure.chatMessages.forEach((chatMessage) => {
                            chatMessage.message = chatMessage.message
                                    .replace(/\n/g, '<br>')
                                    .replace(/__(.*?)__/g, '<u>$1</u>')
                                    .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
                                    .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
                        });
                        // update all unread messages
                        if (this.procedure.chatMessages.some((item) => item.senderType === 'agent' && item.read === false)) {
                            procedureService.setChatMessagesAsRead(this.procedure._id);
                        }
                        $timeout(() => $scope.$apply());
                        $timeout(() => {
                            const messagesContainer = document.querySelector(this.messagesContainer);
                            if (messagesContainer) {
                                messagesContainer.scrollTo({
                                    top: messagesContainer.scrollHeight,
                                    behavior: 'smooth'
                                });
                            }
                        }, 123);
                    }).catch((err) => {
                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                    });
                }
            };

            $scope.chat.init();

            break;

        default:

            console.log('stop here !');
    }

});